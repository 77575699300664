import React from "react";
import './contact.css';

import facebook from './facebook.png'

const Contact = () => {
    return(
<section class="mb-4">

<h2 className="h1-responsive font-weight-bold text-center my-4">Contact us</h2>

<p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will get back to you as soon as possible.</p>
<div>
<p className="text-center w-responsive mx-auto mb-5">Be sure to like our Facebook page to keep up with our latest coupons and specials. <a href='https://www.facebook.com/JDsChimneySolutions/'><img src={facebook} alt="Facebook" className="imgc"/></a> </p>

</div>

<div class="row">

    <div className="col-md-2"></div>

    <div className="col-md-5 mb-md-0 mb-5">
        <form id="contact-form" name="contact-form" action="https://api.web3forms.com/submit" method="POST">
        <input type="hidden" name="access_key" value="6829a86f-6b21-4238-ac22-47265a20f136"/>
            <div className="row">

                <div className="col-md-6">
                    <div className="md-form mb-0">
                    <input type="text" name="name" className="form-control" required/>
                        <label for="name" className="">Your name</label>
                    </div>
                </div>
               
                <div className="col-md-6">
                    <div className="md-form mb-0">
                    <input type="email" name="email" className="form-control" required/>
                        <label for="email" className="">Your email</label>
                    </div>
                </div>
               

            </div>

            <div className="row">

                <div className="col-md-12">

                    <div className="md-form">
                        <textarea name="message" type="text" rows="2" className="form-control md-textarea" required></textarea>
                        <input type="hidden" name="redirect" value="https://web3forms.com/success"/>
                        <label for="message">Message</label>
                    </div>

                </div>
            </div>
            
            <div className="text-center text-md-left">
                <button type="submit">Submit Form</button>        </div>
            <div className="status"></div>
        </form>

        
    </div>

    <div className="col-md-2 b text-center">
        <ul className="list-unstyled mb-0">
            <li><i className="fas fa-map-marker-alt fa-2x"></i>
                <p>JD'S Chimney Solutions</p>
            </li>

            <li><i className="fas fa-phone mt-4 fa-2x"></i>
                <p>859-816-1822</p>
            </li>

            <li><i className="fas fa-envelope mt-4 fa-2x"></i>
                <p>jdschimneysolutions@gmail.com</p>
            </li>
        </ul>
    </div>
    

</div>

</section>

)
}

export default Contact;