import React from "react";
import logo from './logo.png'
import './logo.css'

class NavBar extends React.Component {

    render() {
        const {onRouteChange} = this.props;
    return(
        <div>
            <header className="black-80 tc pv4 avenir">
                <img alt='Chimney Services in Northern Kentucky' className="logo" src={logo}></img>
                <h1 className="mt2 mb0 baskerville i fw1 f1">JD'S Chimney Cleaning & Repair, Fireplace Inspections, Dryer Vent Cleaning</h1>
                <nav className="bt bb tc mw7 center mt4">
                    <p className="f6 f5-l link bg-animate black-80 hover-bg-lightest-blue dib pa3 ph4-l pointer"  onClick={() => onRouteChange('home')}>Home</p>
                    <p className="f6 f5-l link bg-animate black-80 hover-bg-light-green dib pa3 ph4-l pointer"  onClick={() => onRouteChange('ourwork')}>Our Work</p>
                    <p className="f6 f5-l link bg-animate black-80 hover-bg-light-blue dib pa3 ph4-l pointer" onClick={() => onRouteChange('about')}>Services</p>
                    <p className="f6 f5-l link bg-animate black-80 hover-bg-light-yellow dib pa3 ph4-l pointer"  onClick={() => onRouteChange('contact')}>Contact</p>
                </nav>
            </header>
        </div>
)
}
}

export default NavBar;