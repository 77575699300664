import React from "react";
import './description.css'


const Description = () => {
    return(
        <div className="container">
            <div className="text">
                <h2>CHIMNEY & DRYER VENT CLEANING in Northern KY</h2>
                <p>At JD's Chimney Solutions, we're committed to providing high-quality workmanship on each and every project. We can discuss options with you, whether it's wood burning, natural gas, or a hot water heater.  Our company provides clear estimates to customers throughout Northern Kentucky and surrounding areas. </p>
                <p>We have years of combined experience behind us, and we're not finished until the job has been done correctly. Customers rely on us for:</p>
                <ul>
                    <li>Chimney Cleaning & Repair</li>
                    <li>Fireplace Services</li>
                    <li>Dryer Vent Cleaning</li>
                </ul>
                <p>When you choose JD's Chimney Solutions for  your fireplace & chimney needs  you'll receive a 100% guarantee on the results.  We are family owned and fully insured.  We're proud to serve Northern KY and surrounding areas, with honesty and integrity. Our company is looking forward to your call today.</p>
            </div>
        </div>
    )
}

export default Description;