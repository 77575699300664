import './App.css';
import React from 'react';

//Components
import NavBar from './Components/NavBar/navbar';
import Reviews from './Components/Reviews/reviews';
import Footer from './Components/Footer/footer';
import About from './Components/About/about';
import OurWork from './Components/Our Work/ourwork';
import Description from './Components/Description/description';
import Contact from './Components/Contact/contact';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      route: 'home',
    };
  }

  onRouteChange = (route) => {
    this.setState({route: route});
  }

  render() {
    const {route} = this.state;
  return (
    <div className="App">
      <NavBar onRouteChange={this.onRouteChange}/>
    { route === 'home'
    ?
    <div>
      <Reviews/>
      <hr></hr>
      <Description/>
      <hr></hr>
      <Contact/>
      </div>
      : ( route === 'ourwork'
      ?
        <OurWork/>
      : ( route === 'about'
      ?
        <About/>
      : ( route ==='contact'
      ? 
        <Contact/>
      :
        <Footer/>
      )
      )
      )
      }
      <Footer/>
    </div>
  );
}
}

export default App;
