import React from 'react';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';


function Reviews() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel className='carousel' activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <div className='f4 i b ph5 pa5 white'>
        <p>✯✯✯✯✯</p>
        <p>Jeremy and Brandon were efficient, friendly, respectful and very clean. Educated me about fireplace and included No pressure estimate for repair. I will definitely be using them again.</p>
        <p>-Terry Mayo</p>
        </div>

      </Carousel.Item>
      <Carousel.Item>
        <div className='f4 i b ph5 pa5 white'>
            <p>✯✯✯✯✯</p>
            <p>Team arrived promptly, addressed my questions regarding a prefabricated fireplace system, inspected, cleaned and out the door. Great experience and value.</p>
            <p>-Austin Quesinberry</p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='f4 i b ph5 pa5 white'>
            <p>✯✯✯✯✯</p>
            <p>Did a great job! I definitely recommend!</p>
            <p>-Robert Moeller</p>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Reviews;