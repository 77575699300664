import React from 'react';
import './about.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import BA from './B&A.jpg';
import BA2 from './B&A3.jpg';
import fireplace from './fireplace.jpg'
import dryervent from './dryervent.jpg'

function UncontrolledExample() {
  return (
    <div className='tabs'>
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Chimney Services">
          <div className='about'>
              <img alt='Chimney Repair in Covington KY' className='img' src={BA}/>
              <img alt='Fireplace services in Covington KY' className='img' src={BA2}/>
          </div>
          <div className='ph6'>
                  <h2>CHIMNEY CLEANING & REPAIR IN NORTHERN KY </h2>
                  <p>When a fire burns, it produces <strong>creosote, carbon monoxide, and other toxic gases.</strong> If your chimney is blocked up or damaged, these gases can intrude into your home and put you and your loved ones at serious risk. So if you need reliable chimney repair services in a hurry, come to JD's Chimney Solutions.</p>
                  <h4>DEPENDABLE REPAIR SERVICES</h4>
                  <p>When you get your chimney repaired or serviced by JD's Chimney Solutions, you can rest assured that an experienced member of our team will be at your service. We offer comprehensive chimney repair services, and we make it our business to approach each repair job in a detailed, meticulous fashion. Plus, we keep our chimney repair rates reasonable, and we make appointment scheduling a snap.</p>
                  <p>If you suspect that you may have a problem with your chimney, don't wait to get help. Call our team today to schedule an appointment for chimney repairs.</p>
                </div>
      </Tab>
      <Tab eventKey="profile" title="Fireplace Services">
      <div className='about'>
              <img alt='Chimney Repair in Burlington Kentucky' className='img' src={fireplace}/>
          </div>
          <div className='ph6'>
                  <h2>FIREPLACE SERVICES IN NORTHERN KY</h2>
                  <p>A broken or damaged fireplace can be more than just inconvenient. Depending on the circumstances, it could actually put you and your family at risk. Don't take chances when our team is available to help. At JD's Chimney Solutions, we offer reliable fireplace repair services for those in need.</p>
                  <p>Taking the initiative to safeguard your home from fire starts with your decision to have your fireplace cleaned & inspected annually.  To ensure that it is working properly & safe. </p>
                  <p>At JD's Chimney Solutions, we pride ourselves on providing excellent service. Whether it's a simple cleaning, cap replacement, crown repair, or any other chimney or fireplace needs. To learn more about us or to schedule an appointment for fireplace repairs,  call JD's Chimney Solutions today.</p>
                </div>
      </Tab>
      <Tab eventKey="contact" title="Dryer Vent Cleaning">
      <div className='about'>
              <img alt='Chimney Repair in Florence Kentucky' className='img' src={dryervent}/>
          </div>
          <div className='ph6'>
                  <h2>DRYER VENT CLEANING IN NORTHERN KY</h2>
                  <p>Keep your dryer running properly and safely with a helping hand from JD’s Chimney Solutions. We’re pleased to perform dryer vent cleaning in Northern KY & surrounding areas using trusted equipment and techniques. Our team will completely remove all of the built-up lint and debris so that your dryer can operate more efficiently, and you can worry less about fire hazards. You can also count on our crew for chimney and wood stove cleaning. Contact us today to schedule a cleaning for your dryer, we would also be happy to give you an estimate and answer any questions you have.</p>
                  <h4>WHY ROUTINE CLEANING IS CRUCIAL</h4>
                  <p>Many homeowners are unaware of the potential hazards posed by a clogged dryer vent. In fact, according to the U.S. Fire Administration, over 2,900 dryer fires occur each year, resulting in an estimated $35 million in property damage. Most of those fires could’ve been prevented with routine dryer vent cleaning.</p>
                  <p>When a dryer vent becomes clogged with lint and debris, it restricts airflow, causing the dryer to work harder and longer to dry clothes. This can lead to increased energy consumption and higher utility bills. Additionally, a clogged dryer vent can create a buildup of heat, increasing the risk of a fire. Lint is highly combustible, so it can quickly ignite and cause a devastating fire. Protect yourself and your home by scheduling routine visits from JD’s Chimney Solutions.</p>
                </div>
      </Tab>
    </Tabs>
    </div>
  );
}

export default UncontrolledExample;