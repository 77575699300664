import React from "react";
import './ourwork.css';

import img1 from './img1.jpg';
import img2 from './img2.jpg';
import img3 from './img3.jpg';
import img4 from './img4.jpg';

const OurWork = () => {
    return(
      <div className="BAdiv">
        <img src={img1} alt='Chimney Renovation Kentucky' className="BAO ma2"></img>
        <img src={img2} alt='Fix my chimney Northern Kentucky' className="BAO ma2"></img>
        <img src={img3} alt='Dryer Vent Cleaning Northern Kentucky' className="BAO ma2"></img>
        <img src={img4} alt='Dryer Vent Cleaning Covington KY' className="BAO ma2"></img>

      </div>
    )
}

export default OurWork;