import React from "react";
import './footer.css';

const Footer = () => {
    return(
      <div>
      <hr></hr>
        <footer className="pv4 ph3 ph5-m ph6-l mid-gray">
        <small className="f6 db tc">© 2023 <b className="ttu">JD'S Chimney Solutions</b>., All Rights Reserved</small>
        <small className="f6 db tc">JD's Chimney Solutions Covigton, KY Certified Chimney Sweep Chamber of Commerce Member Certified</small>
      </footer>
      </div>
)
}

export default Footer;

